import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/layouts/LayoutDocs.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ButtonsNavigation = makeShortcode("ButtonsNavigation");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Using the correct options for our iframes`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/davidjbradshaw/iframe-resizer/blob/master/docs/parent_page/options.md"
      }}>{`options`}</a>{` below need to be included in the embedding code. We use the following options:`}</p>
    <ul>
      <li parentName="ul">{`checkOrigin: true`}</li>
      <li parentName="ul">{`heightCalculationMethod: 'taggedElement'`}</li>
      <li parentName="ul">{`inPageLinks: true`}</li>
    </ul>
    <p>{`This will make our basic embedding script look like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`style`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token style"
          }}><span parentName="span" {...{
              "className": "token language-css"
            }}>{`
  `}<span parentName="span" {...{
                "className": "token selector"
              }}>{`iframe`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
    `}<span parentName="span" {...{
                "className": "token property"
              }}>{`width`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` 1px`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
    `}<span parentName="span" {...{
                "className": "token property"
              }}>{`min-width`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` 100%`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
  `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{`
`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`style`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`iframe`}</span>{` 
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`myIframeWithChart`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://www.inflowchart.com/chart/examples/example`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`iframe`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token script"
          }}><span parentName="span" {...{
              "className": "token language-javascript"
            }}>{`
  `}<span parentName="span" {...{
                "className": "token function"
              }}>{`iFrameResize`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`checkOrigin`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token boolean"
              }}>{`true`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`heightCalculationMethod`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'taggedElement'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`inPageLinks`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token boolean"
              }}>{`true`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'#myIframeWithChart'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{`
`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <hr></hr>
    <h3>{`Logging`}</h3>
    <p>{`In the example on the previous page we've used `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`log: true`}</code>{` to be able to debug our code. Make sure to remove the log option if you are running in production.`}</p>
    <hr></hr>
    <h3>{`Using multiple iframes on a single page`}</h3>
    <p>{`Make sure you use an unique id for every iframe used on your page! If you use the same id for both embeds only the last included embed will work properly.`}</p>
    <hr></hr>
    <h3>{`Further questions?`}</h3>
    <p>{`Please review the extended docs by by David J. Bradshaw at the `}<a parentName="p" {...{
        "href": "https://github.com/davidjbradshaw/iframe-resizer/"
      }}>{`Github page`}</a>{`.`}</p>
    <hr></hr>
    <ButtonsNavigation linkPrevious="Getting started" linkPreviousTo="/docs/embedding-charts/getting-started" mdxType="ButtonsNavigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      